import AsyncStorage from '@react-native-async-storage/async-storage';
import { WEB_SOCKET_URL, IMAGE_PATH } from '@env';
import moment, { min } from 'moment';
import { Platform, Dimensions, useWindowDimensions } from 'react-native';
import * as Sentry from 'sentry-expo';
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

export const removeItemLocalStorage = async (key) => await AsyncStorage.removeItem(key);

export const getToken = async () => await AsyncStorage.getItem('token');

export const setToken = async (token) => await AsyncStorage.setItem('token', token);

export const setFcmToken = async (token) => await AsyncStorage.setItem('fcm_token', token);

export const getFcmToken = async () => await AsyncStorage.getItem('fcm_token');

export const clearStorage = async () => await AsyncStorage.clear();

export const setQueueJoinId = async (id) => await AsyncStorage.setItem('uqid', id);

export const setUserInterestQueueParam = async (params) => await AsyncStorage.setItem('userInterestQueueParma', params);

export const getQueueJoinId = async () => await AsyncStorage.getItem('uqid');

export const getUserInterestQueueParam = async () => await AsyncStorage.getItem('userInterestQueueParma');

export const showDate = (date) => (date ? moment(date).format('DD MMM YYYY') : '');

export const showTime = (time) => (time ? moment(time, ['HH:mm']).format('hh:mm A') : '');

export const showDateUTC = (date) => (date ? moment.utc(date).format('DD MMM YYYY') : '');

export const showDateTime = (date) => (date ? moment(date).format('DD MMM YYYY hh:mm A') : '');

export const setRoleLocalStorage = async (role) => await AsyncStorage.setItem('role', role);

export const getRoleLocalStorage = async () => await AsyncStorage.getItem('role');

export const getTimeDifferenceInSeconds = (targetTimeString) => {
  const now = new Date();
  const [hours, minutes, seconds] = targetTimeString.split(':').map(Number);
  const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
  const diffInMillis = targetTime - now;
  return Math.floor(diffInMillis / 1000); // Convert milliseconds to seconds
};


export const setThemeLocalStorage = async (value) =>
  await AsyncStorage.setItem('isLightTheme', value);

export const getThemeLocalStorage = async () => await AsyncStorage.getItem('isLightTheme');

export const getWebSocketUrl = () => {
  if (Platform.OS === 'web') {
    let wsProtocol = location.protocol === 'http:' ? 'ws' : 'wss';
    let hostName = WEB_SOCKET_URL;
    return wsProtocol + '://' + hostName + '/ws';
  } else {
    let wsProtocol = 'wss';
    let hostName = WEB_SOCKET_URL;
    return wsProtocol + '://' + hostName + '/ws';
  }
};

export const getImageUrl = (fileName, dir = '') => {
  return IMAGE_PATH + dir + fileName;
};

export const getAvatarLabel = (first_name, last_name) => {
  let label = 'N/A';
  if(first_name){
    label = first_name.substring(0, 1).toUpperCase();
  }
  if (last_name) {
    label += last_name.substring(0, 1).toUpperCase();
  }
  return label;
};

export const goBackHandler = (navigation) => {
  // if (navigation.getState().routes.length > 1) {
  //     navigation.goBack();
  //   } else {
  //     navigation.push('DashboardScreen');
  //   }
  if (Platform.OS === 'web') {
    window.history.back();
  } else {
    navigation.goBack();
  }
};

export const errorLogOnServer = (msg) => {
  if (Platform.OS === 'web') {
    Sentry.Browser.captureException(msg);
  } else {
    Sentry.Native.captureException(msg);
  }
};


export const getWeekListForQueueCard = (weekList, holidayList) => {
  let dateArrayList = [];
  let holidayFilter =
    holidayList && holidayList.length > 0
      ? holidayList.filter((x) => moment(moment().format("YYYY-MM-DD")).isSameOrBefore(x.date) && x.serving_capacity === 0)
      : [];

  for (var i = 0; i < 7; i++) {
    let date = moment().add(i, 'days');
    let dayNumber = date.isoWeekday() - 1;
    let isHoliday = false;
    let isDayOff = true;
    let start_time = '';
    let end_time = '';
    let isAvailable = false;
    if (
      holidayFilter &&
      holidayFilter.filter((x) => date.format('YYYY-MM-DD') == x.date).length > 0
    ) {
      isHoliday = true;
    }

    if (weekList && weekList.length > 0 && isHoliday === false) {
      let filterWeekList = weekList.filter((x) => x.day_number === dayNumber);
      if (filterWeekList.length > 0) {
        isDayOff = false;
        if (filterWeekList[0]?.start_time) {
          start_time = filterWeekList[0]?.start_time;
        }
        if (filterWeekList[0]?.end_time) {
          end_time = filterWeekList[0]?.end_time;
          let beginningTime = moment(date.format('YYYY-MM-DD') + ' ' + filterWeekList[0]?.end_time);
          var endTime = moment();
          isAvailable = beginningTime.isAfter(endTime);
        }
      }
    }
    dateArrayList[i] = {
      date,
      text: date.format('dddd').substring(0, 1),
      dayNumber,
      isHoliday,
      isDayOff,
      start_time,
      end_time,
      isAvailable
    };
  }

  let availableDayID = '';
  let dateArrayIsAvailableList = dateArrayList.filter((x) => x.isAvailable === true);
  if (dateArrayIsAvailableList.length > 0) {
    availableDayID = dateArrayIsAvailableList[0].dayNumber;
  }

  if (availableDayID !== '') {
    dateArrayList = dateArrayList.map((item) => {
      item.isAvailable = false;
      if (availableDayID === item.dayNumber) {
        item.isAvailable = true;
      }
      return item;
    });
  }
  dateArrayList.sort(function (a, b) {
    return a.dayNumber - b.dayNumber;
  });

  return dateArrayList;
};

export const getDisabledHolidayListAdvancedBooking = (weekData, holidayData,allowAdvancedDay) => {

  let holidayList = holidayData.map((i) => new Date(i.date));
  let weekList = weekData;
  const results = [];
  let start = moment().format('YYYY-MM-DD');
  let end = moment().add(parseInt(allowAdvancedDay) , 'd').format('YYYY-MM-DD');
  let weekdayDisabled = [];
  for (let i = 0; i < 7; i++) {
    let filterWeekList = weekList.filter((x) => x.day_number === i);
    if(filterWeekList.length === 0){
      weekdayDisabled.push(i);
    }
  }
  
  while(moment(start) <= moment(end)){
    if(weekdayDisabled.includes((moment(start).isoWeekday()) - 1)){
      results.push(new Date(start));
    }
    start = moment(start).add(1, 'days').format("YYYY-MM-DD");
  }
  return holidayList.concat(results);
};

export const appointmentColorInfo = () =>{
  return [
    {
      label: "Unconfirmed",
      value: "Unconfirmed"
    },
    {
      label: "Confirmed",
      value: "Confirmed"
    },
    {
      label: "Buffer",
      value: "Buffer"
    },
    {
      label: "Serving",
      value: "Serving"
    },
    {
      label: "Completed",
      value: "Completed"
    },
    {
      label: "Cancel / Removed",
      value: "Removed"
    }
  ]
}

export const livePlanColorInfo = () =>{
  return [
    {
      label: "Waiting",
      value: "Waiting"
    },
    {
      label: "Serving",
      value: "Serving"
    },
    {
      label: "Skipped",
      value: "Skipped"
    }
  ]
}

export const normalizeFontSize = (size) => {
  const BASE_WIDTH = 1920;
  const BASE_HEIGHT = 1080;
  
  const scale = Math.min(SCREEN_WIDTH / BASE_WIDTH, SCREEN_HEIGHT / BASE_HEIGHT);
  return Math.max(Math.round(size * scale), size * 0.5); // Ensure minimum font size doesn't drop below 50% of original
};

export const updateNormalizeFontSize = () => {
  const { width, height } = Dimensions.get('window');
  SCREEN_WIDTH = width;
  SCREEN_HEIGHT = height;
};

export const getIsMobileDevice = () => {
  const { width } = useWindowDimensions();
   return width < 769
};

export const generateTimeSlots = (start, end, interval) => {
  const timeSlots = [];
  let current = convertTo24Hour(start);
  const endTime = convertTo24Hour(end);

  while (current < endTime) {
    const startTime = formatAMPM(current);
    current.setMinutes(current.getMinutes() + interval);
    const endTime = formatAMPM(current);
    timeSlots.push({ start_time: startTime, end_time: endTime });
  }

  return timeSlots;
};

export const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return new Date(1970, 0, 1, hours, minutes);
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const getQueueJoinShareLink = (id) => {
    return location.protocol + '//' + location.host + '/join-queue/' + id;
};

export const getTrackingLink = (id) => {
    return location.protocol + '//' + location.host + '/track-joined-queue-position/' + id;
};
