import { QApi } from '../baseQuery';
import {
  USER_LOGIN,
  USER_SIGNUP,
  USER_GET_DETAIL,
  SEND_OTP,
  VERIFY_OTP,
  UPDATE_PROFILE,
  GET_NOTIFICATION_LIST,
  GET_SUBSCRIPTION_PLAN_LIST,
  USER_SUBSCRIBE_PLAN,
  GET_PAYMENT_HISTORY_LIST,
  GET_REPORT_DATA,
  GENERATE_REPORT,
  HELP_AND_SUPPORT,
  DELETE_NOTIFICATION,
  FEEDBACK,
  LOGOUT,
  BUSINESS_DETAIL_UPDATE,
  CONTACT_DETAIL_SEND
} from '../../config/ApiList';
export const UserApi = QApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetail: builder.query({
      query: () => {
        return {
          url: USER_GET_DETAIL,
          method: 'GET'
        };
      },
      invalidatesTags: ['UserApi']
    }),
    getReportData: builder.query({
      query: () => {
        return {
          url: GET_REPORT_DATA,
          method: 'GET'
        };
      },
      invalidatesTags: ['UserApi']
    }),
    login: builder.mutation({
      query: (body) => {
        return {
          url: USER_LOGIN,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: SEND_OTP,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    verifyOtp: builder.mutation({
      query: (body) => {
        return {
          url: VERIFY_OTP,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    signup: builder.mutation({
      query: (body) => {
        return {
          url: USER_SIGNUP,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        return {
          url: UPDATE_PROFILE,
          method: 'PUT',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    getNotificationList: builder.query({
      query: (params) => {
        return {
          url: GET_NOTIFICATION_LIST,
          method: 'GET',
          params: params
        };
      },
      invalidatesTags: ['UserApi']
    }),
    getSubscriptionPlanList: builder.query({
      query: () => {
        return {
          url: GET_SUBSCRIPTION_PLAN_LIST,
          method: 'GET'
        };
      },
      invalidatesTags: ['UserApi']
    }),
    userSubscribePlan: builder.mutation({
      query: (body) => {
        return {
          url: USER_SUBSCRIBE_PLAN,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    getPaymentHistoryList: builder.query({
      query: () => {
        return {
          url: GET_PAYMENT_HISTORY_LIST,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserApi']
    }),
    generateReport: builder.mutation({
      query: ({ body, fileType }) => {
        let url = GENERATE_REPORT;
        if(fileType) {
          url = `${url}/${fileType}`;
        }
   
        return {
          url,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    generateReport: builder.mutation({
      query: ({ body, fileType }) => {
        let url = GENERATE_REPORT;
        if (fileType) {
          url = `${url}/${fileType}`;
          return {
            url,
            method: 'POST',
            body,
            responseHandler: (response) => response.blob(), // Parse response as Blob
          };
        }
        else{
          return {
            url,
            method: 'POST',
            body
          };
        }
      },
      invalidatesTags: ['UserApi'],
    }),
    helpAndSupport: builder.mutation({
      query: (body) => {
        return {
          url: HELP_AND_SUPPORT,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    deleteNotification: builder.mutation({
      query: (body) => {
        return {
          url: DELETE_NOTIFICATION,
          method: 'PUT',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    feedBack: builder.mutation({
      query: (body) => {
        return {
          url: FEEDBACK,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    logout: builder.mutation({
      query: (body) => {
        return {
          url: LOGOUT,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    businessDetailUpdate: builder.mutation({
      query: (body) => {
        return {
          url: BUSINESS_DETAIL_UPDATE,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),
    contactDetailSend: builder.mutation({
      query: (body) => {
        return {
          url: CONTACT_DETAIL_SEND,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: ['UserApi']
    }),

    })
});

export const {
  useGetUserDetailQuery,
  useGetReportDataQuery,
  useLoginMutation,
  useSignupMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useUpdateProfileMutation,
  useGetNotificationListQuery,
  useGetSubscriptionPlanListQuery,
  useUserSubscribePlanMutation,
  useGetPaymentHistoryListQuery,
  useGenerateReportMutation,
  useHelpAndSupportMutation,
  useDeleteNotificationMutation,
  useFeedBackMutation,
  useLogoutMutation,
  useBusinessDetailUpdateMutation,
  useContactDetailSendMutation
} = UserApi;
