export const USER_LOGIN = 'user/login';
export const USER_SIGNUP = 'user/signup';
export const USER_GET_DETAIL = 'user/get_user';
export const SEND_OTP = 'user/send_otp';
export const VERIFY_OTP = 'user/verify_otp';
export const UPDATE_PROFILE = 'user/edit_user';
export const CREATE_QUEUE = 'queue/create';
export const UPDATE_QUEUE = 'queue/edit';
export const GET_QUEUE_LIST = 'queue/list';
export const JOIN_QUEUE = 'queue_user/join_queue';
export const GET_USER_QUEUE_LIST = 'queue_user/list';
export const ADD_USER_QUEUE = 'queue/add_user';
export const ASSIGN_USER_TO_QUEUE = 'queue/add_user_in_queue';
export const GET_QUEUE_DETAIL = 'queue/details';
export const GET_USER_QUEUE_DETAIL = 'queue_user/details';
export const GET_DASHBOARD_DETAIL = 'queue/dashboard';
export const GET_QUEUE_ACTION_DETAIL = 'queue/action_details';
export const UPDATE_QUEUE_STATUS = 'queue/action';
export const NEXT_USER_QUEUE = 'queue/next_user';
export const SKIP_USER_QUEUE = 'queue/skip_user';
export const MOVE_TO_END_QUEUE = 'queue/move_to_last_user';
export const MOVE_TO_WAITING_QUEUE = 'queue/move_to_waiting_user';
export const REMOVE_USER_QUEUE = 'queue/remove_user';
export const BATCH_PROCESS_ACTION = 'queue/batch_action';
export const BATCH_SERVING_USER_ACTION = 'queue/serving_batch_user_action';
export const UNDO_PROCESS_ACTION = 'queue/undo_user';
export const GET_NOTIFICATION_LIST = 'user/notifications';
export const GET_USER_QUEUE_SLOT_DETAIL = 'queue/slot_details';
export const DELETE_QUEUE = 'queue/delete';
export const USER_LEAVE_QUEUE = 'queue_user/left_user';
export const GET_SUBSCRIPTION_PLAN_LIST = 'user/subscription_plan';
export const USER_SUBSCRIBE_PLAN = 'user/user_subscribe';
export const GET_PAYMENT_HISTORY_LIST = 'user/payment_history';
export const GET_REPORT_DATA = 'user/queue_data';
export const GENERATE_REPORT = 'reports/queue_users';
export const HELP_AND_SUPPORT = 'user/help_and_support';
export const DELETE_NOTIFICATION = 'user/delete_notification';
export const FEEDBACK = 'user/user_feedback';
export const LOGOUT = 'user/logout';
export const CONTINUE_JOIN_EXISTING_USER = 'queue_user/continue_joining_with_existing_user';
export const UPDATE_USER_SERVING_TIME = 'queue/update_serving_time';
export const BUSINESS_DETAIL_UPDATE = 'user/business_details';
export const GET_USER_JOIN_DROP_DOWN_LIST = 'queue_user/joined_user_droplist';
export const GET_ADVANCED_BOOKING_SLOT_DETAIL = 'queue/booking_slots';
export const ADD_APPOINTMENT_USER_REQUEST_TO_QUEUE = 'queue/add_user_appointment_request';
export const GET_APPOINTMENT_USER_LIST = 'queue/fetch_appointment_details';
export const MANAGE_APPOINTMENT_ACTION = 'queue/manage_user_appointment';
export const USER_QUEUE_INTEREST_DETAIL = 'queue_user/interested';
export const FETCH_USER_INTEREST_INTO_QUEUE = 'queue_user/fetch_interested_users';
export const QUEUE_USER_CONFIRM_CHECK_AVAIBILITY = 'queue/check_availability';
export const CONTACT_DETAIL_SEND = 'support/register_interest';
export const SEAT_UPDATE_SERVING_CAPACITY = 'queue/update';
export const QUEUE_USER_ADD_MOBILE_NUMBER = 'queue_user';
export const LEAVE_QUEUE_WITHOUT_AUTH = 'queue_user/left_user_anonymous';
export const DOWNLOAD_QR_CODE_PDF = 'queue/qr_code';