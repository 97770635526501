import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AlertErrorIcon({ width = 24, height = 24, color = 'black' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <Path d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" fill={color} />
    </Svg>
  )
}

export default AlertErrorIcon;
