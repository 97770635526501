import { createSlice } from '@reduxjs/toolkit';
const loadingSlice = createSlice({
  name: 'SET_LOADING',
  initialState: false,
  reducers: {
    setLoading: (state, action) => {
      return action.payload;
    }
  }
});

export const { setLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
