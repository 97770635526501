import { createSlice } from '@reduxjs/toolkit';
const themeSlice = createSlice({
  name: 'SET_THEME',
  initialState: false,
  reducers: {
    setLightTheme: (state, action) => {
      return action.payload;
    }
  }
});

export const { setLightTheme } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
